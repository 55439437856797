import Footer from "../components/footer";
import NavBar from "../components/navbar";
import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import NewsItem from "../components/newsItem";

export default function News() {
  const pb = new PocketBase("https://friendsofstandrewsstatepark.org");
  let [newsItems, setNewsItems] = useState([]);
  useEffect(() => {
    (async () => {
      const records = await pb.collection("news").getFullList({
        sort: "-created",
        fields: "id,image,title,description,created",
      });
      setNewsItems(records);
    })();
  }, []);
  return (
    <div className="content d-flex flex-column justify-content-between w-100 h-auto fsa-body-bg news">
      <NavBar />
      <div className={"d-flex justify-content-center flex-column"}>
        <div className="d-flex flex-column  justify-content-center align-content-center align-items-center">
          {newsItems.length > 0 ? (
            newsItems.map((item, inx) => {
              return (
                <div className={"d-flex justify-content-center"}>
                  <NewsItem
                    key={item.id}
                    image={item.image}
                    recKey={item.id}
                    title={item.title}
                    description={item.description}
                    date={item.created}
                  />
                </div>
              );
            })
          ) : (
            <div className={"d-flex add-text-shadow h1"}>Nothing to Report</div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
