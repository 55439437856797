import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import PocketBase from "pocketbase";

const initialOptions = {
  "client-id":
    "AcF6WJ9hDUtDBtTnnjwwlP5iyNCRNFO-aZj-4mC9th1QrZbcxhgBPkZJ1zXSMDHB_Kp3Ty2zxXjQWRsS",
  currency: "USD",
  intent: "capture",
};

const pb = new PocketBase("https://friendsofstandrewsstatepark.org");

export default function donations() {
  let orderIDLoc;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ErrorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [orderID, setOrderID] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
    }
    if (ErrorMessage) {
      alert(ErrorMessage);
    }
  }, [success, ErrorMessage]);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Friends of St. Andrew's State Park Membership",
            amount: {
              currency_code: "USD",
              value: donationAmount.current.value,
            },
          },
        ],
      })
      .then((orderID) => {
        orderIDLoc = orderID;
        setOrderID(orderID);
        return orderID;
      });
  };
  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      const data = {
        paypal_order_id: orderIDLoc,
        first_name: payer.name.given_name,
        last_name: payer.name.surname,
        email: payer.email_address,
        payer_id: payer.payer_id,
        amount: donationAmount.current.value,
      };

      const record = async () => {
        await pb.collection("donors").create(data);
      };
      record().then((r) => setSuccess(true));
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let donationAmount = useRef(0);
  return (
    <div className="content d-flex flex-fill fsa-body-bg add-text-shadow">
      <div className="d-flex flex-column flex-fill justify-content-between">
        <NavBar />
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center">
            <h1>Donations</h1>
            <h2>Thank you for helping us help St Andrews State Park</h2>
          </div>
          <div className="d-flex pt-5 flex-column w-50 align-items-center">
            <h3>
              A tribute is one of the most meaningful gifts you can give. Help
              the Friends achieve their mission while honoring a loved one. The
              Friends of St Andrews State Park, Inc. is a nonprofit charitable
              citizen support organization formed in support of St. Andrews
              State Park to accomplish the Florida State Park mission “to
              provide resource-based recreation while preserving, interpreting
              and restoring natural and cultural resources”.
            </h3>
            <div className={"m-3 p-3"}>
              <span className={"h4"}>Donation Amount:</span>
              <input
                ref={donationAmount}
                type={"number"}
                min={"0"}
                pattern="\d+"
                step={".01"}
                className={"form-control"}
              />
            </div>
            <div className="m-3 p-3">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              </PayPalScriptProvider>
            </div>
            <h2>
              *Please mail checks to: Friends of St. Andrews State Park, 5401
              State Park Circle, Panama City Beach, FL 32408
              <br />
              *We do accept most Credit Cards. For any assistance, please{" "}
              contact us, thank you!
            </h2>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
