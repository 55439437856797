import React from "react";
export default function NavBar() {
  return (
    <div className="d-flex flex-row h-auto pb-1 w-100 align-items-center justify-content-lg-between fsa-nav">
      <a
        href="/"
        className="d-flex flex-row btn btn-lg btn-primary  justify-content-center align-items-center"
      >
        Friends of St. Andrews
      </a>
      <div className="d-flex btn-group flex-row flex-wrap  align-items-center text-decoration-underline">
        <a className="btn btn-primary" href="/">
          Home
        </a>
        <a className="btn btn-primary" href="/news">
          News
        </a>
        <a className="btn btn-primary" href="/join">
          Join
        </a>
        <a className="btn btn-primary" href="/donations">
          Donations
        </a>
        <a className="btn btn-primary" href="/events">
          Events
        </a>
        {/*<a className="btn btn-primary" href="/merchandise">*/}
        {/*  Merchandise*/}
        {/*</a>*/}
        <a className={"btn btn-primary"} href="/sponsors">
          Sponsors
        </a>
        <a className="btn btn-primary" href="/about">
          About
        </a>
      </div>
    </div>
  );
}
