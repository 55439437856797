import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./routes/root";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import ErrorPage from "./error";
import Join from "./routes/join";
import Donations from "./routes/donations";
import Events from "./routes/events";
import About from "./routes/about";
import News from "./routes/news";
import Sponsors from "./routes/sponsors";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/news",
    element: <News />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/join",
    element: <Join />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/donations",
    element: <Donations />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/events",
    element: <Events />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/merchandise",
  //   element: <Merchandise />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sponsors",
    element: <Sponsors />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <div className="d-flex h-100">
    <RouterProvider router={router} />
  </div>
);
