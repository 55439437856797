import React from "react";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import logo from "../logo.png";

export default function Root() {
  return (
    <div className="content d-flex flex-fill fsa-body-bg add-text-shadow">
      <div className="d-flex flex-column flex-fill justify-content-between">
        <NavBar />
        <div className="d-flex flex-column align-items-center my-3">
          <img className="d-flex w-50 h-auto img-fluid" src={logo} alt="logo" />
          <div className="thing-or-other mt-5 bg-opacity-50 text-light mb-2 p-2">
            <h3>We are Friends of St. Andrews State Park.</h3>
            <h3>We are nature lovers, families, artists, beach walkers,</h3>
            <h3> photographers, retirees, bird watchers, and writers </h3>
            <h3>who volunteer our time to help support the park.</h3>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
