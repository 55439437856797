import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className="content d-flex flex-fill align-items-center justify-content-center fsa-body-bg"
    >
      <div className="d-flex text-primary">
        <h1>{error.statusText || error.message}</h1>
      </div>
    </div>
  );
}
