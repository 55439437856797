import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import PocketBase from "pocketbase";
import Parser from "html-react-parser";

export default function about() {
  const pb = new PocketBase("https://friendsofstandrewsstatepark.org");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let [boardMembers, setBoardMembers] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let [membersAtLarge, setMembersAtLarge] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let [codeOfEthics, setCodeOfEthics] = useState();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      const boardMembers = await pb.collection("board_members").getFullList({
        sort: "order_of_appearance",
      });
      const membersAtLarge = await pb
        .collection("members_at_large")
        .getFullList({
          sort: "-created",
        });
      const coe = await pb.collection("documents").getFullList({
        sort: "created",
      });
      setBoardMembers(boardMembers);
      setCodeOfEthics(coe);
      setMembersAtLarge(membersAtLarge);
    })();
  }, []);
  return (
    <div className="content d-flex flex-fill fsa-body-bg">
      <div className="d-flex flex-column flex-fill justify-content-between">
        <NavBar />
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-column align-items-center add-text-shadow">
              <h1 className="">Friends of St. Andrew's State Park</h1>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center card text-body m-2">
                <div className="card-header bg-primary text-light w-100 align-items-center">
                  <h2>Board of Directors:</h2>
                </div>
                <h3 className="card-body d-flex w-100 justify-content-center">
                  <ul className="list-group mx-3">
                    {boardMembers ? (
                      boardMembers.map((member, inx) => {
                        return (
                          <li>
                            {member.title}: {member.name}
                          </li>
                        );
                      })
                    ) : (
                      <span>None Found</span>
                    )}
                  </ul>
                </h3>
              </div>
              <div className="d-flex align-items-center card text-body m-2">
                <div className="card-header bg-primary text-light w-100 align-items-center">
                  <h2>Members At Large:</h2>
                </div>
                <h3 className="card-body d-flex">
                  <ul className="list-group">
                    {membersAtLarge ? (
                      membersAtLarge.map((member, inx) => {
                        return <li>{member.name}</li>;
                      })
                    ) : (
                      <span>None Found</span>
                    )}
                  </ul>
                </h3>
              </div>
            </div>
          </div>
          <div className={"d-flex justify-content-center flex-column mx-3"}>
            <div className={"d-flex card mx-3"}>
              <div className={"d-flex flex-column "}>
                <div
                  className={"d-flex card-header bg-primary add-text-shadow"}
                >
                  <h3>Friends Code of Ethics:</h3>
                </div>
                <div
                  className={
                    "d-flex card-body text-dark align-items-center mx-3 flex-column"
                  }
                >
                  {codeOfEthics ? Parser(codeOfEthics[0].body) : "Not Found"}
                </div>
              </div>
            </div>
            <div className={"d-flex card "}>
              <div className={"d-flex flex-column"}>
                <div
                  className={
                    "d-flex w-100 card-header bg-primary add-text-shadow"
                  }
                >
                  <h3>Bylaws:</h3>
                </div>
                <div
                  className={
                    "d-flex card-body align-items-center text-dark flex-column"
                  }
                >
                  {codeOfEthics ? Parser(codeOfEthics[1].body) : "Not Found"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
