import Parser from "html-react-parser";

export default function NewsItem({ date, description, recKey, title, image }) {
  return (
    <div className="d-flex flex-column m-1 w-75 card justify-content-center">
      <div className="d-flex justify-content-between card-header bg-primary add-text-shadow">
        <span className="d-flex add-text-shadow">{title} </span>
        <span className="d-flex add-text-shadow">
          {new Date(
            new Date(date).setHours(new Date(date).getHours())
          ).toLocaleString("en-US", {
            hour12: true,
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>
      <div className="d-flex card-body justify-content-between bg-info">
        {image.length > 0 && (
          <span className="d-flex bg-info w-25 ">
            <div id={recKey}>
              <div>
                <div key={image}>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <img
                    className="d-block w-100 "
                    src={`https://friendsofstandrewsstatepark.org/api/files/news/${recKey}/${image}`}
                    alt={`${image}`}
                  ></img>
                </div>
              </div>
            </div>
          </span>
        )}
        <span className="d-flex flex-grow-1 m-2 flex-column align-content-center align-items-center justify-content-center text-dark">
          {Parser(description)}
        </span>
      </div>
    </div>
  );
}
