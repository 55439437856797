import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import PocketBase from "pocketbase";

const initialOptions = {
  "client-id":
    "AcF6WJ9hDUtDBtTnnjwwlP5iyNCRNFO-aZj-4mC9th1QrZbcxhgBPkZJ1zXSMDHB_Kp3Ty2zxXjQWRsS",
  currency: "USD",
  intent: "capture",
};
const pb = new PocketBase("https://friendsofstandrewsstatepark.org");
export default function Join() {
  let orderIDLoc;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ErrorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [orderID, setOrderID] = useState(false);
  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
    }
    if (ErrorMessage) {
      alert(ErrorMessage);
    }
  }, [success, ErrorMessage]);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Friends of St. Andrew's State Park Membership",
            amount: {
              currency_code: "USD",
              value: membershipLevel.current.value,
            },
          },
        ],
      })
      .then((orderID) => {
        orderIDLoc = orderID;
        setOrderID(orderID);
        return orderID;
      });
  };
  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      const data = {
        paypal_order_id: orderIDLoc,
        first_name: payer.name.given_name,
        last_name: payer.name.surname,
        email: payer.email_address,
        payer_id: payer.payer_id,
        payment_amount: membershipLevel.current.value,
      };

      const record = async () => {
        await pb.collection("members").create(data);
      };
      record().then((r) => setSuccess(true));
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  let membershipLevel = useRef();
  return (
    <div className="content d-flex flex-column justify-content-between  fsa-body-bg add-text-shadow">
      <div className="d-flex flex-column justify-content-between">
        <NavBar />
        <div
          className={
            "d-flex flex-column align-items-center justify-content-between w-100"
          }
        >
          <div className={"d-flex flex-column  w-50 align-items-center"}>
            <div className={"d-flex justify-content-center mt-5"}>
              <h1>JOIN FRIENDS</h1>
            </div>
            <div
              className={
                "d-flex flex-column align-items-center justify-content-between"
              }
            >
              <select
                id={"membershipSelection"}
                ref={membershipLevel}
                className={"form-select w-100 my-2"}
              >
                <option value={"35"}>General Membership - $35.00 USD</option>
                <option value={"100"}>Contributor - $100.00 USD</option>
                <option value={"250"}>Patron - $250.00 USD </option>
                <option value={"500"}>Benefactor - $300.00 USD</option>
                <option value={"1000"}>Altruist - $1000.00 USD</option>
              </select>
              <div className={"d-flex w-100 justify-content-center"}>
                <PayPalScriptProvider options={initialOptions}>
                  <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                  />
                </PayPalScriptProvider>
              </div>
            </div>
          </div>
          <div className={"d-flex flex-column justify-content-center w-50 h3"}>
            <p>
              Membership and donation benefits include: 12 Free Entrances into
              the Park with Membership Card • Annual Bonfire & Chili Cookout at
              the Park • Annual Membership Meeting & Christmas Party •
              Presentations at the Friends Building Volunteer Opportunities •
              Satisfaction of Supporting the St. Andrews State Park since all
              monies raised by Friends are used specifically to support the
              activities of the Park
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
