import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import PocketBase from "pocketbase";
import Parser from "html-react-parser";

export default function events() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [eventsList, setEventsList] = useState({ future: [], past: [] });
  const pb = new PocketBase("https://friendsofstandrewsstatepark.org");
  const today = new Date();
  today.setDate(today.getDate() - 1);
  today.setUTCHours(0, 0, 0, 0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      const futureEvents = await pb.collection("event").getFullList({
        sort: "event_time",
        fields: "id,event_title,event_time,event_description",
        filter: `event_time>='${today.toISOString()}'`,
      });
      const pastEvents = await pb.collection("event").getFullList({
        sort: "-event_time",
        fields: "id,event_title,event_time,event_description",
        filter: `event_time<'${today.toISOString()}'`,
      });
      setEventsList({ future: futureEvents, past: pastEvents });
    })();
  }, []);
  return (
    <div className="content d-flex flex-column w-100 h-100 justify-content-between fsa-body-bg">
      <NavBar />
      <div className="d-flex flex-column list-group flex-fill justify-content-start align-items-center align-content-center pt-5 pb-5">
        <div className="h1 add-text-shadow text-light-cust">
          Upcoming Events:
        </div>
        {eventsList.future.length > 0 ? (
          eventsList.future.map(function (d) {
            return (
              <div
                key={d.id}
                className="card d-flex flex-column justify-content-center align-content-center w-75 h-75 m-1"
              >
                <div
                  key={d.id}
                  className="card-header d-flex justify-content-between align-content-between flex-fill bg-primary add-text-shadow"
                >
                  <span className="d-flex">{d.event_title} </span>
                  <span className="d-flex">
                    {new Date(
                      new Date(d.event_time).setHours(
                        new Date(d.event_time).getHours() + 3
                      )
                    ).toLocaleString("en-US", {
                      hour12: true,
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <div className="d-flex card-body bg-info align-content-center justify-content-center text-dark mw-100">
                  {Parser(d.event_description)}
                </div>
              </div>
            );
          })
        ) : (
          <div
            className={
              "d-flex w-75 h2 justify-content-center add-text-shadow text-light-cust"
            }
          >
            Nothing scheduled for the near future
          </div>
        )}
        <div className="h1 mt-5 text-dark-cust add-text-shadow d-flex align-content-center justify-content-center align-items-center">
          Past Events:
        </div>
        {eventsList.past.length > 0 ? (
          eventsList.past.map(function (d) {
            return (
              <div
                key={d.id}
                className="card d-flex flex-column justify-content-center align-content-center w-75 h-75 m-1"
              >
                <div
                  key={d.id}
                  className="card-header d-flex justify-content-between align-content-between flex-fill bg-primary add-text-shadow"
                >
                  <span className="d-flex">{d.event_title} </span>
                  <span className="d-flex">
                    {new Date(
                      new Date(d.event_time).setHours(
                        new Date(d.event_time).getHours() + 3
                      )
                    ).toLocaleString("en-US", {
                      hour12: true,
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <div className="d-flex card-body bg-info align-content-center justify-content-center text-dark">
                  {Parser(d.event_description)}
                </div>
              </div>
            );
          })
        ) : (
          <div
            className={
              "d-flex h2 w-75 text-dark-cust justify-content-center add-text-shadow"
            }
          >
            Nothing to look back upon
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
