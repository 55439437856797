import React from "react";
import { AiFillPhone } from "react-icons/ai";
import { BiEnvelope } from "react-icons/bi";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

export default function Footer() {
  return (
    <div className="fsa-footer d-flex flex-column">
      <div className="d-flex flex-row justify-content-around">
        <div className="d-flex flex-column m-1 p-1">
          <p>Related Sites:</p>
          <ul className="list-group">
            <li className="list-inline-item">
              <a
                href="https://www.floridastateparks.org/park/St-Andrews"
                target="_blank"
                rel="noreferrer"
              >
                St. Andrews State Park
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://floridastateparks.org/"
                target="_blank"
                rel="noreferrer"
              >
                Florida State Parks
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.visitpanamacitybeach.com/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Panama City Beach
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="http://www.visitflorida.com/en-us.html"
                target="_blank"
                rel="noreferrer"
              >
                Visit Florida
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="http://turtlewatch.org/"
                target="_blank"
                rel="noreferrer"
              >
                Panama City Beach Turtle Watch
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://floridastateparksfoundation.org/"
                target="_blank"
                rel="noreferrer"
              >
                Florida State Park Foundation
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://myfwc.com/" target="_blank" rel="noreferrer">
                Florida Fish and Wildlife
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex flex-column m-1 p-1">
          <p>Contact Us:</p>
          <ul className="list-group">
            <li className="list-inline-item">
              <AiFillPhone />
              <a href="tel:8507086100">Call The Park: +1 (850) 708-6100</a>
            </li>
            <li className="list-inline-item">
              <BiEnvelope />
              <a href="mailto: friendssasp@gmail.com">
                Email Us At: friendssasp@gmail.com
              </a>
            </li>
            <li className="list-inline-item">
              <BsFacebook />
              <a
                href="https://www.facebook.com/friendsofstandrewsstatepark/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li className="list-inline-item">
              <BsInstagram />
              <a
                href="https://www.instagram.com/friends_standrewsstatepark/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li className="list-inline-item">
              <BsTwitter />
              <a
                href="https://twitter.com/friendsstandrew"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex m-1 p-1 justify-content-center">
        Friends of St. Andrews State Park
        <br />
        5401 State Park Circle Panama City Beach, FL 32408
      </div>
    </div>
  );
}
