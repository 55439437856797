import NavBar from "../components/navbar";
import Footer from "../components/footer";
import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";

export default function sponsors() {
  const pb = new PocketBase("https://friendsofstandrewsstatepark.org");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let [sponsorList, setSponsorList] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      const records = await pb.collection("corporate_donors").getFullList({
        sort: "-created",
      });
      setSponsorList(records);
    })();
  }, []);
  return (
    <div className="content d-flex flex-fill fsa-body-bg">
      <div className="d-flex flex-column flex-fill justify-content-between">
        <NavBar />
        <div className="d-flex flex-column align-items-center my-3">
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex thing-or-other flex-column align-items-center add-text-shadow">
              <h1 className="m-2 add-text-shadow p-1">
                We would like to thank each and every one of our sponsors listed
                below:
              </h1>
            </div>
            <div className={"d-flex flex-column card w-75 mt-5"}>
              <div
                className={"d-flex card-header add-text-shadow bg-primary h1"}
              >
                Sponsors:
              </div>
              <div
                className={
                  "d-flex align-items-center card-body text-dark align-content-center justify-content-center align-items-center flex-wrap"
                }
              >
                {sponsorList.length > 0 ? (
                  sponsorList.map((sponsor, inx) => {
                    return (
                      <img
                        className={"d-flex w-25 h-auto m-3 align-items-center"}
                        src={`http://localhost:8090/api/files/corporate_donors/${sponsor.id}/${sponsor.logo}`}
                        alt={sponsor.company_name}
                      />
                    );
                  })
                ) : (
                  <div>None Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
